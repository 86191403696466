import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   button: {
      margin: theme.spacing(1),
   },
}),
);

export default function Welcome(props) {
   const classes = useStyles();

   const handleEcommerceClick = () => {
      props.history.push('/login');
   }

   const handleVansClick = () => {
      props.history.push('/login-vans');
   }

   const handleVansMxClick = () => {
      props.history.push('/login-vans-mx');
   }

   const handleWranglerClick = () => {
      props.history.push('/login-wrangler');
   }

   return (
      <div>
         <h1 style={{ paddingLeft: '1rem' }}>Welcome (Store WiFi)</h1>
         <Button
            // size="small"
            color="primary"
            className={classes.button}
            onClick={handleEcommerceClick}
         >
            Home
         </Button>
         <Button
            // size="small"
            color="primary"
            className={classes.button}
            onClick={handleVansClick}
         >
            Vans
         </Button>
         <Button
            // size="small"
            color="primary"
            className={classes.button}
            onClick={handleVansMxClick}
         >
            Vans Mix
         </Button>
         <Button
            // size="small"
            color="primary"
            className={classes.button}
            onClick={handleWranglerClick}
         >
            Wrangler
         </Button>
      </div>
   );
}
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Welcome from './components/Welcome';
import Vans from './components/Vans';
import VansMx from './components/VansMx';
import Wrangler from './components/Wrangler';
// import ECommerce from './components/ECommerce';

import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Welcome} />
          {/* <Route exact path='/login' component={ECommerce} /> */}
          <Route exact path='/login' component={Vans} />
          <Route exact path='/login-vans' component={Vans} />
          <Route exact path='/login-vans-mx' component={VansMx} />
          <Route exact path='/login-wrangler' component={Wrangler} />
        </Switch>
      </BrowserRouter>
    </div >
  );
}

export default App;
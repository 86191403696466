import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { getSecrets, resolveHost } from '../utils/utils';

import '../css/signup.css';

const url = resolveHost();
const secrets = getSecrets();

function useQuery() {
   const { search } = useLocation();
   return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Vans() {
   const [authToken, setAuthToken] = React.useState(null);
   const [email, setEmail] = React.useState();
   const [firstName, setFirstName] = React.useState();
   const [lastName, setLastName] = React.useState();
   const [zipCode, setZipCode] = React.useState();
   const [signUpSF, setSignUpSF] = React.useState(true);
   const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
   const [privacyPolicyTouched, setPrivacyPolicyTouched] = React.useState(false);
   const [agreeToTnC, setAgreeToTnC] = React.useState(false);
   const [agreeToTnCTouched, setAgreeToTnCTouched] = React.useState(false);
   const [storeId, setStoreId] = React.useState();
   const [errorEmail, setErrorEmail] = React.useState();
   const [errorFirstName, setErrorFirstName] = React.useState();
   const [errorLastName, setErrorLastName] = React.useState();
   const [errorZipcode, setErrorZipcode] = React.useState();

   React.useEffect(() => {
      fetch(`${secrets.url}`, {
         method: "POST",
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
         body: `grant_type=client_credentials&client_id=${secrets.clientId}&client_secret=${secrets.clientSecret}&scope=${secrets.scope}`,
      })
         .then(res => res.json())
         .then(res => setAuthToken(res.access_token))
         .catch(err => console.log(`err: ${err}`))
   }, []);

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
   };

   const handleLastNameChange = (event) => {
      setLastName(event.target.value);
   };

   const handleZipCodeChange = (event) => {
      setZipCode(event.target.value);
   };

   const handleSignUpSFChange = (checked) => {
      setSignUpSF(!checked);
   };

   const handlePrivacyPolicyChange = (checked) => {
      setPrivacyPolicy(!checked);
      setPrivacyPolicyTouched(true);
   };

   const handleAgreeToTnCChange = (checked) => {
      setAgreeToTnC(!checked);
      setAgreeToTnCTouched(true);
   };

   let query = useQuery();
   const handleJoin = () => {
      clearErrorMessage();
      if (validateForm()) {
         axios.post(`${url}/logController?node_mac=${query.get("node_mac")}&user_email=${email}&First_name=${firstName}&Last_name=${lastName}&zipcode=${zipCode}&signup=${agreeToTnC}&signupSF=${signUpSF}`,
            {},
            {
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${authToken}`,
               }
            })
            .then(res => {
               console.log(`Success: ${res}`);
               window.location.replace('http://n73.network-auth.com/splash/grant?continue_url=http://www.vans.com/');
            })
            .catch(err => {
               console.log(`Error: ${err}`);
            });
      }
   }

   const clearErrorMessage = () => {
      setErrorEmail("");
      setErrorFirstName("");
      setErrorLastName("");
      setErrorZipcode("");
   }

   const validateForm = () => {
      if (email === undefined || email.trim().length === 0) {
         setErrorEmail("Please enter the Email!");
         return false;
      }
      if (!isEmailValid()) {
         setErrorEmail("Please enter a valid Email Address!");
         return false;
      }
      if (firstName === undefined || firstName.trim().length === 0) {
         setErrorFirstName("Please enter the FirstName!");
         return false;
      }
      if (lastName === undefined || lastName.trim().length === 0) {
         setErrorLastName("Please enter the LastName!");
         return false;
      }
      if (zipCode === undefined || zipCode.trim().length === 0) {
         setErrorZipcode("Please enter the ZipCode!");
         return false;
      }
      if (zipCode !== undefined && zipCode.trim().length > 0 && !isZipCodeValid()) {
         setErrorZipcode("Please enter a valid Zip Code for this area store!");
         return false;
      }
      return true;
   }

   const isZipCodeValid = () => {
      return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode);
   }

   const isEmailValid = () => {

      if (email !== null && email !== undefined && email !== "") {
         if (email.indexOf("@") < 1 || email.lastIndexOf(".") < email.indexOf("@") + 2 || email.lastIndexOf(".") + 2 >= email.length)
            return false;
         else
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
      } else
         return false;

   }

   const handleSkip = () => {
      axios.post(`${url}/skipController?node_mac=${query.get("node_mac")}&user_email=${email}&First_name=${firstName}&Last_name=${lastName}&zipcode=${zipCode}&storeId=${storeId}`,
         {},
         {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `Bearer ${authToken}`,
            }
         })
         .then(res => {
            console.log(`Success: ${res}`);
            window.location.replace('http://n73.network-auth.com/splash/grant?continue_url=http://www.vans.com/');
         })
         .catch(err => {
            console.log(`Error: ${err}`);
         });
   }

   return (
      <div className="Vans">
         <div id="emailSignup">
            <div className="header">
               <div className="logo">
                  <img src="/Vanslogo.gif" alt="VANS OFF THE WALL" />
               </div>
            </div>
            <div className="main">
               <h1 style={{
                  fontFamily: `"franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif`,
                  fontSize: '20px',
                  fontWeight: '700',
                  backgroundColor: '#cc071f',
                  color: '#fff',
                  lineHeight: '26px',
                  display: 'inline',
                  paddingLeft: '3px',
                  paddingRight: '3px',
               }}>Thanks for</h1>
               <br />
               <h1 style={{
                  fontFamily: `"franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif`,
                  fontSize: '20px',
                  fontWeight: '700',
                  backgroundColor: '#cc071f',
                  color: '#fff',
                  lineHeight: '26px',
                  display: 'inline',
                  paddingLeft: '3px',
                  paddingRight: '3px',
               }}>Shopping at Vans!</h1>
               <p style={{
                  fontFamily: `"franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif`,
                  fontSize: '15px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  marginTop: '8px',
                  marginBottom: '20px',
               }}>Join Vans Family to get inside information, exclusive designs, member-only experiences, points and rewards.</p>
               <div className="form">
                  <ul>
                     <li>
                        <input
                           maxLength="128"
                           value={email}
                           id="email"
                           name="user_email"
                           placeholder="Email Address*"
                           required
                           type="email"
                           style={{
                              // backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;)',
                              backgroundRepeat: 'no-repeat',
                              backgroundAttachment: 'scroll',
                              backgroundSize: '16px 18px',
                              backgroundPosition: '98% 50%',
                              cursor: 'auto'
                           }}
                           onChange={handleEmailChange}
                        />
                        <div style={{ color: 'red', fontSize: 'small' }}>
                           {errorEmail}
                        </div>
                     </li>
                     <li><input
                        maxLength="128"
                        value={firstName}
                        required
                        id="fname"
                        name="First_name"
                        placeholder="First Name*"
                        type="text"
                        onChange={handleFirstNameChange}
                     />
                        <div style={{ color: 'red', fontSize: 'small' }}>
                           {errorFirstName}
                        </div>
                     </li>
                     <li><input
                        maxLength="128"
                        value={lastName}
                        required
                        id="lname"
                        name="Last_name"
                        placeholder="Last Name*"
                        type="text"
                        onChange={handleLastNameChange}
                     />
                        <div style={{ color: 'red', fontSize: 'small' }}>
                           {errorLastName}
                        </div>
                     </li>
                     <li><input
                        maxLength="128"
                        value={zipCode}
                        required
                        id="zip"
                        name="zipcode"
                        placeholder="Zip Code*"
                        type="number"
                        onChange={handleZipCodeChange}
                     />
                        <div style={{ color: 'red', fontSize: 'small' }}>
                           {errorZipcode}
                        </div>
                     </li>
                  </ul>

                  <p className="requirements">*All fields required for Sign Up</p>
                  <br />
                  <div style={{
                     fontFamily: `"franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif`,
                     fontSize: '12px',
                     fontWeight: '700',
                     color: '#2b2b2b',
                     lineHeight: '12px',
                     margin: '0',
                     paddingTop: '4px',
                  }}>
                     <input
                        type="checkbox"
                        name="signupSF"
                        id="signupSF"
                        checked={signUpSF}
                        onChange={() => handleSignUpSFChange(signUpSF)}
                     />
                     Sign up for Vans news, events and offers
                     <br />
                     <input
                        type="checkbox"
                        name="privacypolicy"
                        id="privacypolicy"
                        checked={privacyPolicy}
                        onChange={() => handlePrivacyPolicyChange(privacyPolicy)}
                     />
                     I agree to Vans <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vans.com/en-us/company/privacy"
                     >
                        Privacy Policy
                     </a>
                     {privacyPolicyTouched && !privacyPolicy && <div style={{ margin: 0, padding: 0, fontSize: '10px', color: 'red' }}>
                        You must accept the Privacy Policy.
                     </div>}
                     <br />
                     {/* <!-- below check box is responsible to make calls for the stellar --> */}
                     <input
                        type="checkbox"
                        name="signup"
                        id="signup"
                        checked={agreeToTnC}
                        onChange={() => handleAgreeToTnCChange(agreeToTnC)}
                     />
                     I agree to Vans Family <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vans.com/en-us/family/terms-conditions"
                     >
                        Terms and Conditions
                     </a>
                     {agreeToTnCTouched && !agreeToTnC && <div style={{ margin: 0, padding: 0, fontSize: '10px', color: 'red' }}>
                        You must accept the Terms and Conditions.
                     </div>}
                     <br />
                  </div>

                  <div style={{ display: 'flex', padding: '0.3rem 0', marginTop: '.5rem' }}>
                     <div className="itemGroup">
                        <div className="itemCell" style={{ paddingRight: '20px' }}>
                           <button
                              type="submit"
                              id="submit"
                              name="submit"
                              onClick={handleJoin}
                              disabled={!agreeToTnC || !privacyPolicy}
                           >
                              JOIN
                           </button>
                        </div>
                     </div>
                     <div className="itemGroup">
                        <div className="itemCell">
                           <button
                              type="submit"
                              id="submit"
                              name="submit"
                              onClick={handleSkip}
                           >
                              Skip
                           </button>
                        </div>
                     </div>
                  </div>
                  {/* <div className="privacyPad">
                     <a
                        className="privacy"
                        href="https://www.vans.com/en-us/company/privacy"
                        target="_blank"
                        rel="noreferrer"
                     >
                        See Our Privacy Policy
                     </a>
                  </div> */}
               </div>
            </div>
            <div className="footer" style={{
               backgroundColor: '#000',
               padding: '27px 16px 27px 20px',
               marginTop: '24px',
            }}>
               <p style={{
                  fontFamily: `"franklin-gothic-urw-cond","Arial Narrow Bold",Arial,sans-serif`,
                  fontSize: '10px',
                  fontWeight: '500',
                  lineHeight: '14px',
                  color: '#fff',
                  margin: '0',
               }}>
                  Vans Inc. 1588 South Coast Dr Costa Mesa, CA 92626<br />
                  © 2017 Vans Inc. All rights reserved.
               </p>
            </div>
         </div>
      </div >
   );
}